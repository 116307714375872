import { Routes, Route } from "react-router-dom";
import HomeV1 from "../pages/homeV1";
import Member from "../pages/member";
import Admin from "../pages/admin";
import Blogs from "../pages/blogs";
import BlogDetails from "../pages/blogDetails";

function App() {
  return (
    <Routes>
      <Route path="/" element={<HomeV1 />} exact />
      <Route path="/userinfo" element={<Member />} exact/>
      <Route path="/administrator" element={<Admin />} />
      <Route path="/blogs" element={<Blogs />} />
      <Route path="/post" element={<BlogDetails />} />
    </Routes>
  );
}

export default App;
