import contract from '../contracts/M_NFT.json';
import { ethers } from 'ethers';
import { isMetaMaskInstalled, ethereum } from '../config';



export const mint = async (mint_amount) => {
    if(isMetaMaskInstalled()){
        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();
        const contractAddress = process.env.REACT_APP_CONTRACT_ADDRESS;
        const nftContract = new ethers.Contract(contractAddress, contract, signer);
        let txnHash = await nftContract.mint(mint_amount, {
            gasLimit: "285000",
            // value: ethers.utils.parseEther((0.03 * mint_amount).toString())
            value: 1000 * mint_amount
        })
        return txnHash
    }
}

export const totalMintCount = async () => {
    if(isMetaMaskInstalled()){
        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner().provider;
        const contractAddress = process.env.REACT_APP_CONTRACT_ADDRESS;
        const nftContract = new ethers.Contract(contractAddress, contract, signer);

        let totalMintCount = await nftContract.totalSupply();

        return totalMintCount;
    }
}

export const totalBalance = async () => {
    if(isMetaMaskInstalled()){
        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();
        const contractAddress = process.env.REACT_APP_CONTRACT_ADDRESS;
        const nftContract = new ethers.Contract(contractAddress, contract, signer);

        let totalBalance = await nftContract.balanceOf(ethereum.selectedAddress);

        return totalBalance;
    }
}

export const tokenOfOwnerByIndex = async (index) => {
    if(isMetaMaskInstalled()){
        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();
        const contractAddress = process.env.REACT_APP_CONTRACT_ADDRESS;
        const nftContract = new ethers.Contract(contractAddress, contract, signer);

        let tokenId = await nftContract.tokenOfOwnerByIndex(ethereum.selectedAddress,index);

        return tokenId;
    }
}
