 
import SectionTitle from "../../../../common/sectionTitle";
import Item from "../item";
import TeamStyleWrapper from "./Items.style";
import {useModal} from "../../../../utils/ModalContext";
import {useEffect, useState} from "react";
import {totalBalance,tokenOfOwnerByIndex} from "../../../../utils/web3mint";

const Items = () => {
  const { walletModalHandle, metamaskModalHandle, account, isWalletAlreadyConnected, disconnectWalletFromApp } = useModal();
  const [count, setCount] = useState(0);
  const [arrayData,setArrayData] = useState(Array())

  useEffect(() => {
    const calculateRemainingItems = async () => {
      let totaltMintedItems = await totalBalance();
      setCount(totaltMintedItems);
      setArrayData(Array.from(Array(totaltMintedItems.toNumber()).keys()))
    }
    calculateRemainingItems().then(r => {});
  },[account]);

  return (
    <TeamStyleWrapper>
      <div className="bithu_team_sect v1_team_sect" id="item">
        <div className="v1_team_overlay">
          <div className="container">
            <SectionTitle title="My Item" subtitle="Item" />
            <div className="bithu_team_content">
              {/*<div className="row">*/}
              {/*  {data.slice(0,count)?.map((item, i) => (*/}
              {/*    <div key={i} className="col-lg-3 col-md-4 col-sm-6">*/}
              {/*      <Item {...item} />*/}
              {/*    </div>*/}
              {/*  ))}*/}
              {/*</div>*/}
              <div className="row">
                {arrayData.map((item, i) => (
                    <div key={i} className="col-lg-3 col-md-4 col-sm-6">
                      <Item index={i} />
                    </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </TeamStyleWrapper>
  );
};

export default Items;
