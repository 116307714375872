import TeamMemberStyleWrapper from "./Item.style";
import {totalBalance,tokenOfOwnerByIndex} from "../../../../utils/web3mint";
import {useEffect, useState} from "react";
import data from "../../../../assets/data/characterSlider/characterSlider";
import {default as axios} from "axios";

const Item = ( {index} ) => {
    const [tokenIndex, setTokenIndex] = useState(0);
    const [image,setImage] = useState()
    const [description,setDescription] = useState()
    const [name,setName] = useState()
    const [objUrl,setObjUrl] = useState()
    const SERVER_URL = process.env.REACT_APP_SERVER_URL;
    useEffect(() => {
        const calculateRemainingItems = async () => {
            let tokenIndex = await tokenOfOwnerByIndex(index);
            setTokenIndex(tokenIndex)
            axios.get(SERVER_URL+'/tokens/'+tokenIndex)
                .then(function (response) {
                    // handle success
                    setImage(response.data.image)
                    setDescription(response.data.description)
                    setName(response.data.name)
                    setObjUrl(response.data.objUrl)
                })
                .catch(function (error) {
                    // handle error
                    console.log(error);
                })
                .then(function () {
                    // always executed
                });
        }
        calculateRemainingItems();
    },[]);

    const axios = require('axios').default;


  return (
    <TeamMemberStyleWrapper>
      <div className="bithu_team_card">
        <div className="team_member_img">
          <img src={data[index%8].thumb} alt="bithu nft team avatar" />
        </div>
        <h3>{name}</h3>
        <h4>{description}</h4>
      </div>
    </TeamMemberStyleWrapper>
  );
};

export default Item;
