import checkIcon from "../../images/icon/roadmap_check.svg"
import spinnerIcon from "../../images/icon/roadmap_spiner.png"

const data = [
    {
        icon: checkIcon,
        title: "N",
        subtitle: "70%",
        text: "Normal"
    }, 
    {
        icon: checkIcon,
        title: "R",
        subtitle: "15%",
        text: "Rare"
    }, 
    {
        icon: checkIcon,
        title: "SR",
        subtitle: "6%",
        text: "Super Rare"
    }, 
    {
        icon: spinnerIcon,
        title: "SSR",
        subtitle: "2.5%",
        text: "Super Super Rare"
    }, 
    {
        icon: spinnerIcon,
        title: "UR",
        subtitle: "1%",
        text: "Ultra Rare"
    }, 
    {
        icon: spinnerIcon,
        title: "EXR",
        subtitle: "0.5% Soldout",
        text: "Extreme Rare"
    }, 
]

export default data;