import {useModal} from "../utils/ModalContext"
import GlobalStyles from "../assets/styles/GlobalStyles";
import Layout from "../common/layout";
import Header2 from "../components/section/header/v1/Header2";
import Item from "../components/section/myItem/v1";
import Profile from "../components/section/profile/v1";
import Footer from "../components/section/footer/v2";
import MintNowModal from "../common/modal/mintNowModal" 
import WalletModal from "../common/modal/walletModal/WalletModal" 
import MetamaskModal from "../common/modal/metamask/MetamaskModal";
import ConnectWallet from "../common/modal/metamask/ConnectWallet";

const Member = () => {
  const { visibility, walletModalvisibility, metamaskModalVisibility, connectWalletModal } = useModal();
  return (
    <>
      <Layout>
        <GlobalStyles /> 
        {visibility && <MintNowModal />}
        {walletModalvisibility && <WalletModal />}
        {metamaskModalVisibility && <MetamaskModal/> }
        {connectWalletModal && <ConnectWallet/> }
        <Header2 />
        <Profile />
        <Item />
        <Footer />
      </Layout>
    </>
  );
};

export default Member;
