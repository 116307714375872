import {useModal} from "../../../../utils/ModalContext";
import {useEffect, useState} from "react";
import SectionTitle from "../../../../common/sectionTitle";
import TeamStyleWrapper from "./Profile.style";
import MintModalStyleWrapper from "../../../../common/modal/mintNowModal/MintNow.style";
import {totalMintCount, mint,totalBalance} from '../../../../utils/web3mint';
import {isMetaMaskInstalled} from "../../../../config";

const Profile = () => {
    const { walletModalHandle, metamaskModalHandle, account, isWalletAlreadyConnected, disconnectWalletFromApp } = useModal();
    const [ownCount, setCount] = useState(0);
    const refreshTime = process.env.REACT_APP_REFRESH_TIME;

    useEffect(() => {
        isWalletAlreadyConnected();
    },[isWalletAlreadyConnected]);

    useEffect(() => {
        calculateBalance().then(r => {});
    },[account]);

    const calculateBalance = async () => {
        let totaltMintedItems = await totalBalance();
        setCount(totaltMintedItems.toNumber());
    }

    useEffect(() =>{
        const interval = setInterval(() => {
            calculateBalance();
        }, refreshTime);
        return () => clearInterval(interval);

    },[])

    return (
        <TeamStyleWrapper>
            <div className="bithu_team_sect v1_team_sect" id="profile">
                <div className="v1_team_overlay">
                    <div className="container">
                        <SectionTitle title="My Profile" subtitle="Profile"/>
                        <div className="bithu_team_content">
                            <MintModalStyleWrapper className="">
                                <div className="mint_count_list">
                                    <ul>
                                        <li>
                                            <h4>Items</h4>
                                        </li>
                                        <li>
                                            <h5>Own items</h5>
                                            <h5>{ownCount}</h5>
                                        </li>
                                        <li>
                                            <h5>Price</h5>
                                            <h5>0 ETH</h5>
                                        </li>
                                        <li>
                                            <h4>Application</h4>
                                        </li>
                                        <li>
                                            <h5>Username</h5>
                                            <h5>-</h5>
                                        </li>
                                        <li>
                                            <h5>Password</h5>
                                            <h5>***</h5>
                                        </li>
                                    </ul>
                                </div>
                            </MintModalStyleWrapper>
                        </div>
                    </div>
                </div>
            </div>
        </TeamStyleWrapper>
    );
};

export default Profile;
