import styled from "styled-components";

const TeamMemberStyleWrapper = styled.article`
  .bithu_team_card {
    background: rgba(255, 255, 255, 0.05);
    backdrop-filter: blur(10px);
    max-width: 100%;
    height: 300px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    h3 {
      color: #ffffff;
      font-size: 24px;
      line-height: 34px;
      text-align: center;
      margin-top: 28px;
      margin-bottom: 4px;
    }

    h4 {
      font-family: "Inter";
      font-style: normal;
      text-align: center;
      font-size: 16px;
      line-height: 19px;
      color: rgba(255, 255, 255, 0.8);
      margin: 0;
    }
  }

  @media only screen and (max-width: 991px) {
    .bithu_team_card {
      h3 {
        font-size: 18px;
        line-height: 28px;
      }
    }
  }
`;

export default TeamMemberStyleWrapper;
